body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 40px;
  overflow-x: hidden;
  background-color: #f3f3f3;
}

@font-face {
  font-family: 'FCBARCELONA-Semibold';
  src: url('https://static.capabiliaserver.com/frontend/clients/barca/certificados/font/FCBARCELONA-Semibold.eot?#iefix') format('embedded-opentype'), url('https://static.capabiliaserver.com/frontend/clients/barca/certificados/font/FCBARCELONA-Semibold.otf') format('opentype'), url('https://static.capabiliaserver.com/frontend/clients/barca/certificados/font/FCBARCELONA-Semibold.woff') format('woff'), url('https://static.capabiliaserver.com/frontend/clients/barca/certificados/font/FCBARCELONA-Semibold.ttf') format('truetype'), url('https://static.capabiliaserver.com/frontend/clients/barca/certificados/font/FCBARCELONA-Semibold.svg#FCBARCELONA-Semibold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FCBARCELONA-Regular';
  src: url('https://static.capabiliaserver.com/frontend/clients/barca/certificados/font/FCBARCELONA-Regular.eot?#iefix') format('embedded-opentype'), url('https://static.capabiliaserver.com/frontend/clients/barca/certificados/font/FCBARCELONA-Regular.otf') format('opentype'), url('https://static.capabiliaserver.com/frontend/clients/barca/certificados/font/FCBARCELONA-Regular.woff') format('woff'), url('https://static.capabiliaserver.com/frontend/clients/barca/certificados/font/FCBARCELONA-Regular.ttf') format('truetype'), url('https://static.capabiliaserver.com/frontend/clients/barca/certificados/font/FCBARCELONA-Regular.svg#FCBARCELONA-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* BEGGIN SCROLL BAR */

.hide-header, .modal-extender-cursado .react-bs-table-sizePerPage-dropdown{
  display: none;
}
/* width */

::-webkit-scrollbar {
  width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background-color: #f8f8f8;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #18283b;
}

/* END SCROLL BAR */

/* BEGIN FORMS */

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #0000ff;
  background-color: #0000ff;
}

/* END FORMS */

.text-primary {
  color: #0000ff!important;
}

.btn {
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  padding: 8px 30px;
  transition: all 180ms;
  -o-transition: all 180ms;
  -webkit-transition: all 180ms;
  -moz-transition: all 180ms;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(213, 213, 213, 0.25);
}

.btn-primary {
  color: #fff;
  background-color: #0000ff;
  border-color: #0000ff;
}

.btn-primary:hover, .btn-primary:focus {
  color: #fff;
  background-color: #18283b;
  border-color: #18283b;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #0000ff;
  background-color: #fff;
  border-color: #0000ff;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #000;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-secondary {
  color: #18283b;
  background-color: transparent;
  border-color: #18283b;
}

.btn-secondary:hover, .btn-secondary:focus {
  color: #0000ff;
  background-color: transparent;
  border-color: #0000ff;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  color: #18283b;
  background-color: #fff;
  border-color: #18283b;
}

.badge-primary {
  color: #fff;
  background-color: #880dff;
}

/* Containers */

.container-fluid {
  width: 95%;
}

.selectdiv {
  position: relative;
}

.selectdiv:after {
  content: '\f078';
  font: normal normal normal 14px/1 FontAwesome;
  color: #0000ff;
  right: 15px;
  top: 17px;
  height: 34px;
  position: absolute;
  pointer-events: none;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1768px;
  }
}

/* IE11 hide native button (thanks Matt!) */

select::-ms-expand {
  display: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  padding: 10px 40px 10px 20px;
  height: auto;
}

/* Animations */

@keyframes slideRight {
  from {
    transform: translateX(100%);
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -o-transform: translateX(100%);
  }
  to {
    transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

/*
  Global Components
*/

.icon-large {
  border: solid 1px #ccc;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  margin: 30px 0;
}

.icon-large .fa {
  height: 100px;
  line-height: 100px;
}

.navbar {
  z-index: 990;
  border-bottom: solid 1px #ccc;
}

.sticky-top {
  z-index: 991;
}

.navbar li {
  margin: 0 10px;
}

.navbar .btn-outline-grey i {
  color: #0000ff;
}

.gVZomo {
  position: relative;
  width: 100%;
  overflow-x: none;
  min-height: 800px;
}

.rdt_TableCell {
  font-size: 15px!important;
}

button, button:focus {
  outline: none;
}

.btn {
  border-radius: 2rem;
  padding: .375rem 1.25rem;
  outline: none !important;
}

.bg-light {
  background-color: #FFF !important;
}

.btn-outline-grey {
  color: #636363;
  border-color: #ccc;
  border-radius: 100px;
  padding: .375rem 1.25rem;
  font-size: 0.9em;
  font-weight: 600;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, .1);
}

.dropdown-toggle::after {
  color: #0200fe;
}

.stickyMenu__cliente p {
  display: inline-block;
}

/*Certificate creation sidebar*/

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  background-color: #FFFFFF;
  overflow-x: hidden;
  transition: 0.5s;
  flex-grow: 1;
  /*ensures that the container will take up the full height of the parent container*/
  overflow-y: auto;
  /*adds scroll to this container*/
}

.sidenav a, .sidenav p {
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  color: #FFFFFF;
  display: block;
  transition: 0.3s;
  margin-bottom: 0px !important;
  font-size: 1em !important;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  text-align: right;
}

@media screen and (max-height: 450px) {
  /*.sidenav {padding-top: 15px;}*/
  .sidenav a {
    font-size: 18px;
  }
}

#overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

.flex-header {
  position: sticky;
  top: 0;
  align-self: flex-start;
  background: -prefix-linear-gradient(left, #003BFF, #880DFF);
  background: linear-gradient(to right, #003BFF, #880DFF);
}

.flex-footer {
  position: sticky;
  bottom: 0;
  align-self: flex-start;
  background-color: #F1F1F1;
}

.navbarstiky {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.03);
}

.navigation-breadcrumb {
  font-size: 0.8em;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.criterio-count {
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #7315FF;
  color: #fff;
  display: block;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -o-border-radius: 100%;
  margin-top: 30px;
  margin-right: 15px;
  font-size: 12px;
}

.header-group {
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.header-group h5:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #7315FF;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  margin-right: 15px;
}

.header-group h5 {
  font-size: 18px;
  margin: 0!important;
}

.box-edit {
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.03);
  -o-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.03);
  padding: 30px;
}

.criterios-listado {
  padding: 0;
  margin: 0;
}

.criterios-listado li {
  list-style: none;
  border-bottom: solid 1px #ccc;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.criterios-listado li span.titulo {
  display: block;
  font-weight: 700;
}

.criterios-listado li span.valor {
  display: block;
}

.button-delete {
  width: 28px;
  height: 28px;
  line-height: 23px;
  text-align: center;
  background-color: #e42b35;
  border: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -o-border-radius: 100%;
}

.button-delete i {
  color: #fff;
  font-size: 14px;
}

.my-modal {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-modal-content {
  background-color: #fff;
  width: 100%;
  max-width: 480px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.max-w-90{
  max-width: 90%;
}

.zindextop{
  z-index: 99999!important;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #0000ff;
  border-color: #0000ff;
}

a {
  color: #0000ff;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0000ff;
}

.dropdown-item.active, .dropdown-item:active a{
  color: #fff;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0000ff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.min-h-50{
  height: 70vh;
}

.my-modal-header {
  background: #003dff;
  background: linear-gradient(to right, #003dff 0%, #7415ff 66%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003dff', endColorstr='#7415ff', GradientType=1);
  padding: 20px 40px;
}

.my-modal-header h6 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
}

.my-modal-header .btn-close {
  width: 25px;
  height: 25px;
  line-height: 23px;
  background-color: rgba(256, 256, 256, 0.1);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  padding: 0;
  margin: 0;
  transition: all 180ms;
  -o-transition: all 180ms;
  -webkit-transition: all 180ms;
  -moz-transition: all 180ms;
}

.my-modal-header .btn-close i {
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: 25px;
}

.my-modal-body {
  padding: 30px 40px;
  flex: 1 1;
  min-height: auto;
  overflow: auto;
}

.my-modal-footer {
  border-top: solid 1px #ccc;
  background-color: #fdfdfd;
  padding: 15px 40px;
}

@media screen and (min-width: 1200px) {
  .select-templtate {
    min-width: 300px;
  }
}

.box-white {
  background-color: #fff;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -o-border-radius: 6px;
  padding: 30px;
}

.custom-modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  padding-top: 4%;
}

.custom-modal-content {
  width: 100%;
  height: auto;
  z-index: 9999;
  top: 0;
  right: 0;
  contain: content;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -o-border-radius: 8px;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .custom-modal-content {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .custom-modal-content {
    width: 95%;
  }
}

.custom-modal-header {
  background: #003dff;
  background: linear-gradient(to right, #003dff 0%, #7415ff 66%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003dff', endColorstr='#7415ff', GradientType=1);
  padding: 17.5px 40px;
}

.custom-modal-header h6 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
}

.custom-modal-header .btn-close {
  width: auto;
  height: auto;
  background-color: rgba(256, 256, 256, 0.1);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  padding: 0;
  margin: 0;
  transition: all 180ms;
  -o-transition: all 180ms;
  -webkit-transition: all 180ms;
  -moz-transition: all 180ms;
  padding: 0px 5px!important;
}

.custom-modal-header .btn-close:hover {
  transform: scale(1.05);
  -o-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  background-color: rgba(256, 256, 256, 0.3);
}

.custom-modal-header .btn-close:hover i {
  color: rgba(256, 256, 256, 0.6);
}

.custom-modal-header .btn-close i {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 0;
}

.custom-modal-body {
  padding: 30px 40px;
  flex: 1 1 0%;
  min-height: auto;
  overflow: auto;
}

.custom-modal-footer {
  border-top: solid 1px #ccc;
  background-color: #fdfdfd;
  padding: 15px 40px;
}

.custom-modal-icon-big {
  background-color: rgb(241, 241, 241);
  border: solid 1px #ccc;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00d499;
  font-size: 40px;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  margin-bottom: 30px;
}

.custom-modal-icon-big-no {
  background-color: rgb(241, 241, 241);
  border: solid 1px #ccc;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ca1533;
  font-size: 40px;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  margin-bottom: 30px;
}

.loading-status {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loading-status .loading-status-container {
  width: 100%;
  background-color: #fff;
  max-width: 450px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -o-border-radius: 6px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  left: 50%;
  top: 50%;
  transform: translateX(-50%)translateY(-50%);
  -moz-transform: translateX(-50%)translateY(-50%);
  -webkit-transform: translateX(-50%)translateY(-50%);
  -o-transform: translateX(-50%)translateY(-50%);
  padding: 30px;
  text-align: center;
}

.content-tree-layout {
  height: 400px;
  max-height: 400px;
  overflow-y: auto;
}

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 75vh;
  margin-left: -30rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .list-group {
  width: 30rem;
  padding: 1rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: -30rem;
  }
}

.btn-round {
  background-color: #003BFF;
  width: 35px!important;
  height: 35px!important;
  border: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -o-border-radius: 100%;
  color: #fff;
  margin-right: 15px;
  outline: none;
}

.btn-round:hover, .btn-round:focus {
  background-color: #003BFF;
  outline: none;
}

.customlabelSearch {
  min-width: 150px;
  margin: 0;
}

.custom-big-search {
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
}

.custom-row-class {
  line-height: 40px;
}

.nav-tabs{
  border: 0;
}

.nav-link{
  color: #666;
  background-color: rgb(233, 233, 233);
  border: 0;
}

.css-1meh2x2-BaseToolTop {
  z-index: 99;
}